import { render, staticRenderFns } from "./ModalArgEdit.vue?vue&type=template&id=06d1f4e0&scoped=true"
import script from "./ModalArgEdit.vue?vue&type=script&lang=js"
export * from "./ModalArgEdit.vue?vue&type=script&lang=js"
import style0 from "./ModalArgEdit.vue?vue&type=style&index=0&id=06d1f4e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d1f4e0",
  null
  
)

export default component.exports